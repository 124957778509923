import React from "react";
import {
  AppProvider,
  AlertProvider,
  DialogProvider,
  NotificationsProvider,
  AuthProvider,
} from "./hooks";
import { AppRouting } from "./routers";
import "./Theme.less";

const App = () => (
  <AppProvider>
    <AlertProvider>
      <DialogProvider>
        <NotificationsProvider>
          <AuthProvider>
            <AppRouting />
          </AuthProvider>
        </NotificationsProvider>
      </DialogProvider>
    </AlertProvider>
  </AppProvider>
);

export default App;
